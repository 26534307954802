.library-items {
  margin-bottom: 2rem;
}
.library-items .ais-Hits-list {
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(3, 1fr);
}
.library-items .ais-Hits-list .ais-Hits-item {
  display: block;
  padding: 0;
}
